<!-- Display loading message while the request is in progress -->
<div *ngIf="isLoading">Updating feature flags, please wait...</div>

<!-- Display success message if update is successful -->
<div *ngIf="successMessage" class="success-message">{{ successMessage }}</div>

<!-- Display error message if an error occurs -->
<div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>


<div *ngFor="let featureFlag of featureFlags">
    <div class="border border-t-0 border-r-0 border-l-0 border-b-1 pb-4">
      <div class="flex justify-between items-center mt-4">
        <span class="font-bold">{{featureFlag.description}} ({{featureFlag.key}})</span><span>
            <prcins-round-toggle-switch [sliderValue]="featureFlag.value" [sliderKey]="featureFlag.key"
            (sliderChanged)="featureFlagChanged($event)" [disabled]="false">
          </prcins-round-toggle-switch>
        </span>
      </div>
    </div>
  </div>

  <div class="flex justify-between mt-4 flex-wrap-reverse">
    <button class="mt-4 es-btn-secondary w-full lg:w-40 text-center" [routerLink]="['../']">Cancel</button>
    <button class="mt-4 es-btn-primary w-full lg:w-40 text-center" (click)="saveFeatureFlags()"
      type="button">Update Feature Flags</button>
  </div>