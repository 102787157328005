<ng-container *ngIf="!hideRightRails && !isPolicyIncomplete">
  <ng-container *ngIf="!rightRailsLoading; else loading">
    <prcins-card *ngIf="showQuickLinks" headerClass="text-es-orange" pt="pt-0">
      <ng-container header-left>
        <img src="assets/svgs/quick-links.svg" alt="">
        <span class="ml-4">Quick Links</span>
      </ng-container>
      <ng-container body *ngIf="rightRail">
        <div class="flex text-es-blue-light mb-2">
          <img class="right-pointer" src="assets/svgs/down-icon-blue.svg" alt="">
          <a [href]="faq" target="_blank">Frequently Asked Questions</a>
        </div>
      </ng-container>
    </prcins-card>

    <ng-container *ngIf="showAgentInfo$ | async">
      <ng-container *ngIf="agentInfoLoaded$ | async">
        <prcins-card headerClass="text-es-orange" pt="pt-0" *ngIf="rightRail?.agentInfo && isPolicyLevel">
          <ng-container header-left>
            <img src="assets/svgs/agent.svg" alt="">
            <span class="ml-4">Your Agent</span>
          </ng-container>
          <ng-container body>
            <p class="font-bold">{{rightRail.agentInfo.agencyName}}</p>
            <p>{{rightRail.agentInfo.agencyAddress}}</p>
            <p>{{rightRail.agentInfo.agencyCity}}, {{rightRail.agentInfo.agencyState}}</p>
            <p class="flex text-es-blue-light mb-2"><img class="mr-2" src="assets/svgs/phone.svg"
                *ngIf="rightRail.agentInfo?.agencyPhone" alt=""><a
                href="tel:{{rightRail.agentInfo?.agencyPhone|telephone}}">{{rightRail.agentInfo?.agencyPhone|telephone}}</a>
            </p>
          </ng-container>

        </prcins-card>
      </ng-container>
    </ng-container>


    <prcins-card headerClass="text-es-orange" pt="pt-0" *ngIf="rightRail">
      <ng-container header-left>
        <img src="assets/svgs/help.svg" alt="">
        <span class="header-font ml-4">Help Center</span>
      </ng-container>
      <ng-container body>
        <ng-container *ngIf="isPolicyLevel">
          <div class="mb-2">
            <p class="font-bold">General Help:</p>
            <p *ngIf="isHoliday" class="desc-font">Open Monday-Friday 8am-5pm</p>
          </div>
          
          <div class="mb-2">
              <ng-container [ngSwitch]="policy.policyInfo.lob" *ngIf="!policy.policyInfo.companyCode.startsWith('MAIP') ">
                <ng-container *ngSwitchCase="'PA'">
                  <p class="flex text-es-blue-light">
                    <img class="mr-2" style="height:23px" src="assets/svgs/text.svg" alt="">
                    <a *ngIf="isDirectOrCaptive; else displayIA" href="sms:22022;?&body=Care">Text “{{"Care"}}”
                      to 22022</a>
                    <ng-template #displayIA>
                      <a href="sms:22022;?&body=Service">Text “{{"Service"}}” to 22022</a>
                    </ng-template>
                  </p>
                  <p class="text-font">Replies within minutes</p>
                </ng-container>
          
                <ng-container *ngSwitchCase="'HO'">
                  <p class="flex text-es-blue-light"><img class="mr-2" style="height:23px" src="assets/svgs/text.svg" alt="">
                    <a href="sms:22022;?&body=Home">Text “{{"Home"}}” to 22022</a>
                  </p>
                  <p class="text-font">Replies within minutes</p>
                </ng-container>
          
                <ng-container *ngSwitchCase="'CA'">
                  <ng-container *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB'">
                    <p class="flex text-es-blue-light"><img class="mr-2" style="height:23px" src="assets/svgs/text.svg"
                        alt=""><a href="sms:22022;?&body=Service">Text “{{"Service"}}” to
                        22022</a>
                    </p> 
                    <p class="text-font">Replies within minutes</p>
                  </ng-container>
                </ng-container>
          
              </ng-container>
          </div>

          <div class="mb-2">
            <p class="flex text-es-blue-light"><img class="mr-2 p-1" style="height:23px"
                src="assets/svgs/email_icon.svg" alt="">
              <a [routerLink]="['../home/contact/email']">Send an Email or Attachment</a>
            </p>
            <p class="text-font">Replies within 3 business days</p>
          </div>
          

          <div *ngIf="!isHoliday" class="mb-2">
            <p class="flex text-es-blue-light"><img class="mr-2 p-1" style="height:23px" src="assets/svgs/phone_icon.svg"
                alt=""><a href="tel:{{rightRail.generalPhone|telephone}}">{{rightRail.generalPhone|telephone}}</a></p>
            <p class="text-font">Wait times may vary</p>
          </div>
          

          <p class="font-bold mt-4">Claims Help:</p>
          <p class="flex text-es-blue-light pb-2"><img class="mr-2 p-1" style="height:23px"
              src="assets/svgs/phone_icon.svg" alt=""><a
              href="tel:{{rightRail.claimsPhone|telephone}}">{{rightRail.claimsPhone|telephone}}</a></p>
          <p *ngIf="showEserviceTxtMessage" class="flex text-es-blue-light pb-2 border-b"><img class="mr-2"
              style="height:23px" src="assets/svgs/text.svg" alt=""><a href="sms:22022;?&body=Claims">Text “Claims” to
              22022</a></p>
        
        <button class="mt-4 es-btn-primary" prcins-tealium-tracker="click" prcins-tealium-event="report_claim"
          (click)="openGeneralClaim()">Report a
          Claim Online</button>
      </ng-container>
    </ng-container>
    </prcins-card>
 
    <prcins-enroll-automatic-payments *ngIf="showEft"></prcins-enroll-automatic-payments>
    <!-- <prcins-edocs-signup *ngIf="showEdocs"></prcins-edocs-signup> -->

  </ng-container>




  <ng-template #eDocsSpinner>
    <prcins-skeleton [hideTimeoutMessage]="false" numOfRows="1"></prcins-skeleton>
  </ng-template>

  <ng-template #loading>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton (onTimeout)="hideRightRail()" numOfRows="5"></prcins-skeleton>
      </div>
    </prcins-card>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton [hideTimeoutMessage]="true" numOfRows="5"></prcins-skeleton>
      </div>
    </prcins-card>
    <prcins-card pt="pt-0">
      <div body>
        <prcins-skeleton [hideTimeoutMessage]="true" numOfRows="5"></prcins-skeleton>
      </div>
    </prcins-card>
  </ng-template>
</ng-container>

<!-- <prcins-edocs-popup [showIf]="rightRail?.showEdocs && showEdocsPopup" (discard)="togglePopup()"></prcins-edocs-popup> -->