<main class="w-full px-4">
  <h1 class="es-h1 mt-4">Email</h1>
  <p>If you need to share an attachment, send us an email at <span class="text-es-blue-light">{{this.helpEmail}}</span> or send us a text by texting "Care" to 22022</p>
  <prcins-notification [notification]="emailStatus"></prcins-notification>
  <prcins-card *ngIf="!spinner; else loading">
    <ng-container body>
      <form class="w-full" [formGroup]="emailForm" #emailFormRef="ngForm">
        <div class="flex items-center justify-between flex-wrap">
          <label class="flex-none" for="">From</label>
          <input formControlName="fromEmail" type="text" trimInput class="w-full lg:w-3/5 es-textfield">
        </div>
        <div class="w-full mt-2"
          *ngIf="!emailForm.get('fromEmail').valid && (emailForm.get('fromEmail').dirty || emailForm.get('fromEmail').touched)">
          <div class="es-error-field" *ngIf="emailForm.get('fromEmail').errors['required']">
            Oops! You forgot to give us your email address.
          </div>
          <div class="es-error-field"
            *ngIf="emailForm.get('fromEmail').errors['email']||emailForm.get('fromEmail').errors['pattern']">
            Hmmm, that doesn't look like a valid email address.
          </div>
        </div>
        <div class="flex mt-6 items-center justify-between flex-wrap">
          <label class="flex-none">What do you need help with?</label>
          <select formControlName="category" class="w-full lg:w-3/5 es-textfield" (change)="onCategoryChange($event)">
            <option *ngFor="let category of categories" [ngValue]="category.value" >{{category.label}}</option>
          </select>
        </div>
        <div class="w-full mt-2"
          *ngIf="!emailForm.get('category').valid && (emailForm.get('category').dirty || emailForm.get('category').touched)">
          <div class="es-error-field" *ngIf="emailForm.get('category').errors['required']">
            Oops! You forgot to choose a category.
          </div>
        </div>
        <div class="flex mt-6 items-center justify-between flex-wrap">
          <label class="flex-none">Enter your question or feedback here</label>
          <textarea formControlName="body" class="w-full es-textfield mt-2" rows="10">
           </textarea>
        </div>
        <div class="w-full mt-2"
          *ngIf="!emailForm.get('body').valid && (emailForm.get('body').dirty || emailForm.get('body').touched)">
          <div class="es-error-field" *ngIf="emailForm.get('body').errors['required']">
            Please enter your message.
          </div>
        </div>
      </form>
      <div class="flex flex-wrap-reverse justify-between">
        <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" [routerLink]="['../..']">Cancel</a>
        <button class="es-btn-primary w-full mt-4 sm:w-40" type="button" (click)="onSubmit()">Submit</button>
      </div>
    </ng-container>
  </prcins-card>

  <prcins-es-popup *ngIf="claims && !dismissed" headerClass="bg-es-blue text-white">
      <div header-left >
        <span>Emailing Claims</span>
      </div>
      <div body>
        <p>If your question is about a claim, that has been reported, please email the adjustor directly using the link
          next to his/her name on the Claim Tab. This will ensure a speedy reply.</p>
        <p class="mt-4">It may take up to 2 to 3 days for us to respond to emails that are not sent
          directly to an adjustor or supervisor associated to your claim.</p>

        <div class="flex flex-wrap-reverse justify-between">
          <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="dismissEmailClaims()">Continue</a>
          <button class="es-btn-primary w-full mt-4 sm:w-40" type="button" [routerLink]="['../../claims/claimslist']">Go
            to Claims</button>
        </div>
      </div>
   
  </prcins-es-popup>
</main>

<ng-template #loading>
    <prcins-card pt="pt-0">
        <div body>
            <prcins-skeleton   numOfRows="10"></prcins-skeleton>
        </div>
    </prcins-card>
</ng-template>