import { Component, OnInit } from '@angular/core';
import { FeatureFlag, FeatureFlagService } from '../services/feature-flag.service';

@Component({
  selector: 'prcins-admin-feature-flags',
  templateUrl: './admin-feature-flags.component.html',
  styleUrls: ['./admin-feature-flags.component.css']
})
export class AdminFeatureFlagsComponent implements OnInit {

  featureFlags = [];

  isLoading = false;
  errorMessage: string | null = null;
  successMessage: string | null = null;

  constructor(private featureFlagService: FeatureFlagService) { }

  ngOnInit() {
    this.featureFlagService.getFeatureFlags().subscribe((data) => {
      this.featureFlags = data ? data : [];
    });
  }

  featureFlagChanged(event) {
    const { key, value } = event;
    const index = this.featureFlags.findIndex(flag => flag.key === key);
    if (index !== -1) {
      this.featureFlags[index] = { ...this.featureFlags[index], ...{ value: value } }
    };
  }


  saveFeatureFlags(): void {
    this.isLoading = true;
    this.errorMessage = null; // Clear previous errors
    this.successMessage = null; // Clear previous success messages

    this.featureFlagService.bulkUpdateFeatureFlags(this.featureFlags).subscribe({
      next: (updatedFlags: FeatureFlag[]) => {
        this.isLoading = false;
        this.successMessage = 'Feature flags updated successfully!';
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMessage = 'Error updating feature flags: ' + (err.message || 'Unknown error');
      }
    });
  }
}



