<div class="hidden lg:flex xl:flex flex-col items-center mb-4 pb-8 w-full">
    <div class="max-w-2xl relative w-full desktop-banner">
        <div class="welcome-banner w-full flex justify-center pt-4">
            <img class="banner-image" src="assets/svgs/banner-desktop.svg" alt="welcome-banner">
        </div>
        <div class="flex justify-center absolute w-full top-40 z-10">
            <img class="welcome-icon" src="assets/svgs/welcome-icon.svg" alt="welcome-icon">
        </div>
    </div>
</div>
