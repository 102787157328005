/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-banner.component";
var styles_AppBannerComponent = [i0.styles];
var RenderType_AppBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppBannerComponent, data: {} });
export { RenderType_AppBannerComponent as RenderType_AppBannerComponent };
function View_AppBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "qrCodeContainer"], ["style", "float:left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["style", "margin-left: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["style", "font-size: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download the Plymouth Rock mobile app!"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Scan the QR Code with your phone to get started. The app gives you access to all of your policies, whenever you need it! "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "text-es-blue-light"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Learn More"]))], null, null); }
function View_AppBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["style", "float:left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/png/new-app-icon.png"], ["width", "55"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["style", "margin-left: 60px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["style", "font-size: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download the Plymouth Rock mobile app!"])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The app gives you access to all of your policies, whenever you need it! "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "text-es-blue-light"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Learn More"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "es-btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openStore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download App"]))], null, null); }
export function View_AppBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "flex flex-col items-center my-4 py-4 px-8 w-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "max-w-2xl relative w-full desktop-banner"], ["style", "background-color: #E5F2FB; padding: 10px; border-radius: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppBannerComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppBannerComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMobile(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isMobile(); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AppBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-app-banner", [], null, null, null, View_AppBannerComponent_0, RenderType_AppBannerComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AppBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppBannerComponentNgFactory = i1.ɵccf("prcins-app-banner", i3.AppBannerComponent, View_AppBannerComponent_Host_0, {}, {}, []);
export { AppBannerComponentNgFactory as AppBannerComponentNgFactory };
