import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CURRENT_POLICY, USER_ID } from '@prcins/constants';
import { ClaimsSearchService } from '../services/claims-search.service';
import { PolicyProviderService } from '../services/policy-provider.service';
import { Subject, Observable } from 'rxjs';
import { INotification } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../notification.service';
import { timeout } from 'rxjs/operators';


@Component({
  selector: 'prcins-contact-email',
  templateUrl: './contact-email.component.html',
  styleUrls: ['./contact-email.component.css']
})
export class ContactEmailComponent implements OnInit {
  spinner = false;
  claims = false;
  dismissed = true;
  helpEmail = '';
  emailForm: FormGroup;
  emailStatus: INotification
  categories = [
    { label: 'Select', value: null },
    { label: 'Policy or Billing Question', value: "PolicyBilling" },
    { label: 'Claims Question', value: "Claims" },
    { label: 'Technical Question', value: "Technical" },
  ]
  constructor(private fb: FormBuilder,
    private claimsService: ClaimsSearchService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute) {
    this.emailForm = this.fb.group({
      fromEmail: [
        sessionStorage.getItem(USER_ID),
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')
        ]
      ],
      category: [this.categories[0].value, [Validators.required]],
      body: ['', [Validators.required]]
    });


  }


  ngOnInit() {
    this.helpEmail = sessionStorage.getItem("general-email");
    this.notificationService.notify$.subscribe(data => this.emailStatus = data);
    this.claimsService.claimsSearchQuery(sessionStorage.getItem(CURRENT_POLICY))
      .subscribe(response => { this.claims = response.data.claimsSearch && response.data.claimsSearch.length > 0 });
  }

  dismissEmailClaims() {
    this.dismissed = true;
  }

  onCategoryChange(event) {
    let value = event.target.value;
    if (value === '2: Claims') {
      this.dismissed = false;
    }
  }

  onSubmit() {
    if(this.emailForm.invalid){
      this.emailForm.markAllAsTouched();
      return false;
    }
    this.spinner = true;
    let { fromEmail, category, body } = this.emailForm.value;
    let policynumber = sessionStorage.getItem(CURRENT_POLICY);
    let request = { fromEmail, subject: category, policynumber, question: body }
    this.notificationService.sendEmail(request).subscribe(result => {
      if (result.messageCode === 'email.success') {
        this.router.navigate(['../..'], { relativeTo: this.route }).then(data => {
          setTimeout(() => this.notificationService.notify$.next({
            type: 'INFO',
            message: 'Email sent! We’ll do our best to send a prompt reply within 3 business days. If you need help sooner, please text “Service” to 22022.'
          }), 1000);
        });
      } else {
        this.notificationService.notify$.next({
          type: 'ERROR',
          message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.'
        });
      }
      this.spinner = false;
    }, error => {
      this.notificationService.notify$.next({
        type: 'ERROR',
        message: 'Oops! We seem to be having a problem. Please try again later or contact us for help.'
      });
      this.spinner = false;
    });
  }
}
