<prcins-notification *ngIf = "this.isLoading" [notification]="{type:'WARN'}">
    Updaing Holiday.. Please Wait
</prcins-notification>

<prcins-notification *ngIf = "this.successMsg" [notification]="{type:'INFO'}">
    {{successMsg}}
</prcins-notification>

<prcins-notification *ngIf = "this.errorMsg" [notification]="{type:'WARN'}">
    {{errorMsg}}
</prcins-notification>

<div class="container">
    <form #holidayForm="ngForm">
        <table class="holiday-table">
            <thead>
                <tr>
                    <th>Holiday</th>
                    <th>Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let holiday of holidayList; let i = index" [ngClass]="{'error-row': holiday.isError, 'new-row': holiday.isNew}" >
                    <td>
                        <input type="text" name="holidayDescription{{i}}" [(ngModel)]="holiday.holidayDescription" required [ngClass]="{'error-column': holiday.isError, 'no-selection': !holiday.isNew}" #holidayDescription="ngModel" placeholder="Enter Holiday Name" [readonly]="!holiday.isNew"/>
                        <div *ngIf="holidayDescription.invalid && holidayDescription.touched" class="error-message">Please enter a valid Name</div>
                    </td>
                    <td>
                        <input type="date" name="holidayDate{{i}}" [(ngModel)]="holiday.holidayDate" required [ngClass]="{'error-column': holiday.isError, 'no-selection': !holiday.isNew}" #holidayDate="ngModel" [readonly]="!holiday.isNew"/>
                        <div *ngIf="holidayDate.invalid && holidayDate.touched" class="error-message">Please enter a valid Date</div>
                    </td>
                    <td class="button-container">
                        <button *ngIf = "holiday.isNew" class="add-btn add-btn-cancel" [disabled]="holidayForm.invalid" (click)="save(holiday)">Save</button>
                        <button class="add-btn add-btn-cancel" (click)="deleteRow(i, holiday)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
<div style="text-align: center; padding-top: 10px;">
    <button class="btn edit-btn" (click)="addRow()" [disabled]="!canAddRow">Add Row</button>
    <button class="btn edit-btn" [routerLink]="['../']">Cancel</button>
</div>
<prcins-es-popup *ngIf="showDeletePopup" headerClass="bg-es-blue text-white">
    <div header-left>
        <span>Delete Holiday</span>
    </div>
    <div body class="items-center justify-between">
        <p class="flex">
            <img src="assets/svgs/help.svg" alt="">
            <span class="ml-2 text-xl">Are you sure you want to delete this Holiday?</span>
        </p>

        <div class="mt-4">
            <button class="add-btn add-btn-primary" (click)="deleteHoliday(true)">Yes, Delete Holiday</button>
            <a class="es-link-secondary w-full mt-4" (click)="deleteHoliday(false)">Cancel</a>
        </div>
    </div>
</prcins-es-popup>