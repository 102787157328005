import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prcins-mobile-app-download',
  templateUrl: './mobile-app-download.component.html',
  styleUrls: ['./mobile-app-download.component.css']
})
export class MobileAppDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
