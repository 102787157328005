/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tabs.component";
var styles_TabsComponent = [i0.styles];
var RenderType_TabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsComponent, data: {} });
export { RenderType_TabsComponent as RenderType_TabsComponent };
function View_TabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTab(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.active; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_1); }); }
export function View_TabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "tab-headers"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "tab-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "prcins-tabs", [], null, null, null, View_TabsComponent_0, RenderType_TabsComponent)), i1.ɵdid(1, 1097728, null, 1, i3.TabsComponent, [], null, null), i1.ɵqud(603979776, 1, { tabs: 1 })], null, null); }
var TabsComponentNgFactory = i1.ɵccf("prcins-tabs", i3.TabsComponent, View_TabsComponent_Host_0, {}, {}, ["*"]);
export { TabsComponentNgFactory as TabsComponentNgFactory };
