/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-app-landing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mobile-app/mobile-app.component.ngfactory";
import * as i3 from "../mobile-app/mobile-app.component";
import * as i4 from "./mobile-app-landing.component";
var styles_MobileAppLandingComponent = [i0.styles];
var RenderType_MobileAppLandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileAppLandingComponent, data: {} });
export { RenderType_MobileAppLandingComponent as RenderType_MobileAppLandingComponent };
export function View_MobileAppLandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-app", [], null, null, null, i2.View_MobileAppComponent_0, i2.RenderType_MobileAppComponent)), i1.ɵdid(1, 4308992, null, 0, i3.MobileAppComponent, [], { allowWebContinue: [0, "allowWebContinue"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MobileAppLandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-mobile-app-landing", [], null, null, null, View_MobileAppLandingComponent_0, RenderType_MobileAppLandingComponent)), i1.ɵdid(1, 114688, null, 0, i4.MobileAppLandingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileAppLandingComponentNgFactory = i1.ɵccf("prcins-mobile-app-landing", i4.MobileAppLandingComponent, View_MobileAppLandingComponent_Host_0, {}, {}, []);
export { MobileAppLandingComponentNgFactory as MobileAppLandingComponentNgFactory };
