<div class="tabs">
    <ul class="tab-headers">
      <li *ngFor="let tab of tabs" [class.active]="tab.active" (click)="selectTab(tab)">
        {{ tab.title }}
      </li>
    </ul>
    <div class="tab-content">
      <ng-content></ng-content>
    </div>
  </div>
  