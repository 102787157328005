import { AfterViewInit, Component, OnInit } from '@angular/core';
import QRCode from 'qrcode';
import { environment } from "@prcins/environments";

@Component({
  selector: 'prcins-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.css']
})
export class AppBannerComponent implements OnInit, AfterViewInit {
  qrData: any = null;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const qrOpts = {
      errorCorrectionLevel: 'L',
      scale: 1,
      width: 85,
      margin: 0
    }

    this.qrData = window.location.href.replace(window.location.origin, "https://mobileqa.plymouthrock.com");
    if (environment.production) {
      this.qrData = window.location.href.replace(window.location.origin, "https://mobile.plymouthrock.com");
    }

    QRCode.toCanvas(this.qrData, qrOpts, function (err, canvas) {
      if (err) throw err

      let container = document.getElementById('qrCodeContainer')
      container.appendChild(canvas)
    });
  }

  isAndroid() {
    return navigator.userAgent.match('Android');
  }

  isIOS() {
    return navigator.userAgent.match('iPad') ||
      navigator.userAgent.match('iPhone') ||
      navigator.userAgent.match('iPod');
  }

  isMobile() {
    return this.isAndroid() || this.isIOS();
  }

  openStore() {
    navigator.clipboard.writeText(this.qrData);

    if(this.isAndroid()) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.prac.eservice";
    } else if(this.isIOS()) {
      window.location.href = "https://apps.apple.com/app/apple-store/id632820710";
    }
  }

}
