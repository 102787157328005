<div class="flex flex-col items-center w-full es-app-container">
  
    <div class="w-full" *ngIf="showIntermediateRegisterationForm;else payment_entry_form">
      <prcins-quick-pay-registration-header [headerText]="'Sign up to easily access your policy information and make payments in fewer clicks.'"></prcins-quick-pay-registration-header>
      <prcins-intermediate-registeration-form (submitted)="onRegistrationSubmitted($event)"></prcins-intermediate-registeration-form>
      <div class="flex justify-center pb-8">
        <button class="es-link-secondary w-full mt-4 cursor-pointer" (click)="onContinueToPaymentForm()">No Thanks, Continue to payment</button>
      </div>
    </div>
    <ng-template #payment_entry_form> 
      <div *ngIf="isPaymentDetailsLoaded && !isReviewInProgress; else loading">
      <h1 class="es-h1 mt-4">Make a Payment</h1>
      <prcins-pending-payments (hasPendingPayments)="showHideNoteSection($event)" [currentPaymentDetails]="paymentDetails"
        [pageType]="'Make a Payment'"></prcins-pending-payments>
      <p *ngIf="showNote"><strong>Note: Enrolled in Automatic Payments?</strong> Payments made within 3 business days of a
        due date may NOT prevent the withdrawal from occurring. If your withdrawal is scheduled for a weekend or holiday, it
        will automatically be processed on the next business day.</p>
      <form [formGroup]="paymentForm" (submit)="onContinue()" #paymentFormRef>
        <ng-container *ngIf="submitted && !paymentFormRef.valid" prcins-tealium-tracker="load"
          prcins-tealium-event="payment_validation_error"></ng-container>
        <prcins-card headerClass="bg-es-blue text-white">
          <span header-left>Payment Amount</span>
          <div body class="mx-4 my-2">
            <div class="flex justify-between font-bold mt-4 pb-2 border border-t-0 border-l-0 border-r-0 border-b-1">
              <span>Next Payment Due</span>
              <span>{{billingDetails?.nextPaymentDueDt}}</span>
            </div>
            <div class="flex justify-between font-bold mt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-b-1">
              <span>Minimum Due</span>
              <span>{{billingDetails?.nextPaymentAmnt | currency}}</span>
            </div>
            <div class="flex justify-between font-bold mt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-b-1">
              <div class="flex flex-col"><span>Balance</span><span class="font-light">(For coverage through
                  {{coverageThrough}})</span>
              </div>
              <span>{{balanceDue | currency}}</span>
            </div>

            <div *ngIf="payInFullFlag && billingDetails && billingDetails.payInFullFlag && billingDetails.payInFullAmnt > 0"
              class="flex justify-between font-bold mt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-b-1">
              <div class="flex flex-col"><span>Pay in Full and Save!</span> </div>
              <span>{{billingDetails?.payInFullAmnt | currency}}</span>
            </div>
            <div class="flex flex-col mt-2">
              <span>Payment Amount</span>
              <div class="mt-2 flex items-center">
                <ng-select class="max-w-xs ng-select-w" formControlName="paymentAmountType"
                  (change)="onPaymentAmountSelected($event)" [clearable]=false [searchable]="false">
                  <ng-option value="%%">Select your amount</ng-option>
                  <ng-option value="balance">Balance {{balanceDue | currency}}</ng-option>
                  <ng-option value="minimum">Minimum Due of {{billingDetails?.nextPaymentAmnt | currency}}</ng-option>
                  <ng-option
                    *ngIf="payInFullFlag && billingDetails && billingDetails.payInFullFlag && billingDetails.payInFullAmnt > 0"
                    value="pif">Pay in Full {{billingDetails?.payInFullAmnt | currency}}</ng-option>
                  <ng-option value="other">Other Amount
                  </ng-option>
                </ng-select>
                <img class="ml-2 cursor-pointer" src="assets/svgs/question-round-blue.svg" alt=""
                  (click)="showHelpText('paymentAmount')">
              </div>

              <input *ngIf="paymentForm.get('paymentAmountType').value === 'other'" class="mt-2 es-textfield max-w-xs"
                currencyMask [options]="{  align: 'left' , allowNegative : false}" placeholder="00.00"
                formControlName="paymentAmount">
              <prcins-error-label [control]="paymentForm.get('paymentAmount')"
                [controlValue]="paymentForm.get('paymentAmount').value" controlLabel="paymentForm_paymentAmount"
                [controlTouched]="paymentForm.get('paymentAmount').touched">
              </prcins-error-label>
            </div>
          </div>
        </prcins-card>
        <prcins-card headerClass="bg-es-blue text-white">
          <span header-left>How would you like to pay</span>
          <div body>
            <div class="flex justify-center mb-4 flex-wrap">
              <p class="w-full">Clicking on <strong>'Bank Account'</strong> or <strong>'Credit/Debit Card'</strong> payment
                method should
                display
                the corresponding fields that display today</p>
              <div *ngIf="!disableEFT"
                class="flex flex-col justify-center items-center border border-2 rounded cursor-pointer bg-es-sky-blue border-es-sky-blue-dark py-4 mr-4 w-32 mt-4"
                (click)="onTogglePaymentMethod('eft')">
                <img src="assets/svgs/bank-account.svg" alt="">
                <span class="text-xs mt-2">Bank Account</span>
                <div class="es-checkmark-container flex items-center justify-center mt-2">
                  <div *ngIf="paymentMethod.eft" class="es-checkmark"></div>
                </div>
              </div>
              <div
                class="flex flex-col justify-center items-center border border-2 rounded cursor-pointer bg-es-sky-blue border-es-sky-blue-dark py-4 mr-4 w-32 mt-4"
                (click)="onTogglePaymentMethod('cc')">
                <img src="assets/svgs/card.svg" alt="">
                <span class="text-xs mt-2">Credit/Debit Card</span>
                <div class="es-checkmark-container flex items-center justify-center mt-2">
                  <div *ngIf="paymentMethod.cc" class="es-checkmark"></div>
                </div>
              </div>
            </div>
            <prcins-error-label [control]="paymentForm.get('paymentOptionSelected')"
              [controlValue]="paymentForm.get('paymentOptionSelected').value"
              controlLabel="paymentForm_paymentOptionSelected"
              [controlTouched]="paymentForm.get('paymentOptionSelected').touched">
            </prcins-error-label>

            <div *ngIf="paymentMethod.eft" class="mt-6 pt-3 border border-t-1 border-l-0 border-r-0 border-b-0">
              <ng-container *ngIf="!addNewBank; else editBankForm">
                <ng-container *ngIf="storedBankAccounts && storedBankAccounts.length>0; else editBankForm">
                  <strong>Your saved bank accounts:</strong>
                  <div *ngFor="let bank of storedBankAccounts">
                    <div class="border rounded border-gray-300 mt-4 p-4">
                      <div class="flex items-center p-3">
                        <ng-container>
                          <prcins-round-radio radioName="eftSavedPaymentMethod" radioValue="{{bank.walletId}}"
                            (radioChanged)="savedPaymentMethodChanged($event, 'eft')"
                            radioChecked="{{bank.defaultInd === 'Yes' || bank.selected? 'checked' : null}}">
                          </prcins-round-radio>
                        </ng-container>
                        <div class="ml-5">
                          <span class="font-whitney-semibold">{{bank.accountType}} ****{{bank.debitAccount}}</span>
                          <p *ngIf="bank.defaultInd === 'Yes'" class="text-es-orange"> (Default)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="onAddNewBank()">Use a Different
                    Account</a>
                    <div class="mt-4" >
                      <div class="mb-2"><label>Payment confirmation Email </label></div>
                      <div class="flex"><input class="es-textfield flex-grow" type="text" formControlName="confirmationEmail"
                        ></div>
                    
                    </div>
                </ng-container>
              </ng-container>
            </div>
            <prcins-error-label [control]="paymentForm.get('eftSavedPaymentMethod')"
              [controlValue]="paymentForm.get('eftSavedPaymentMethod').value"
              controlLabel="paymentForm_paymentOptionSelected"
              [controlTouched]="paymentForm.get('eftSavedPaymentMethod').touched">
            </prcins-error-label>

            <ng-template #editBankForm>
              <h2 class="mt-4 font-bold text-es-orange text-lg">Account Information</h2>
              <prcins-bank-form [eftForm]="paymentForm.get('eftForm')"></prcins-bank-form>
              <div *ngIf="paymentSavingAllowed && this.isWalletFlagEnabled" class="custom-check-box">
                <label class="switch" style="display: inline-block; white-space: nowrap; margin-top: 15px">
                  <input type="checkbox" style="vertical-align: middle; width: 30px;
                  height: 30px;" formControlName="saveThisBank">
                  <span class="ml-2" style="vertical-align: middle;">Save this account for future use</span>
                  <div class="tick"></div>
                </label>
              </div>
            </ng-template>

            <div *ngIf="paymentMethod.cc && !addNewCard"
              class="mt-6 pt-3 border border-t-1 border-l-0 border-r-0 border-b-0">
              <strong>Your saved credit and debit cards:</strong>
              <div *ngFor="let card of storedCreditCards">
                <div class="border rounded border-gray-300 mt-4 p-4">
                  <div class="flex items-center p-3">
                    <ng-container>
                      <prcins-round-radio radioName="ccSavedPaymentMethod" radioValue="{{card.walletId}}"
                        (radioChanged)="savedPaymentMethodChanged($event, 'cc')"
                        radioDisabled="{{card.expiration === 'expired'? 'true' : null}}"
                        radioChecked="{{(card.defaultInd === 'Yes'|| card.selected) && card.expiration !== 'expired'? 'checked' : null}}">
                      </prcins-round-radio>
                      <img class="mx-4 cardType" width="50" height="32" alt="" src="{{card.imageUrl}}">
                    </ng-container>
                    <div>
                      <span class="font-whitney-semibold">{{card.cardDisplayType}} *****{{card.debitAccount}}</span>
                      <div>
                        <div *ngIf="card.defaultInd === 'Yes'" style="display: inline-block;" class="text-es-orange">
                          (Default)</div>
                        <div *ngIf="card.expiration === 'expired'" style="display: inline-block;" class="text-es-orange">
                          (Expired)</div>
                      </div>
                    </div>
                  </div>
                  <div class="ml-16"
                    *ngIf="paymentForm.get('ccSavedPaymentMethod').value === card.walletId && card.expiration !== 'expired'">
                    <div class="mb-2"><label>Security Code (CVV)</label></div>
                    <div class="flex"><input class="es-textfield w-20 prcins-mask-data" type="text" trimInput
                        formControlName="savedCardSecurityCode" prcinsOnlyNumber [minLength]="cvvLength"
                        [maxLength]="cvvLength" autocomplete="off">
                      <img (click)="showHelpText('savedCardSecurityCode')" class="ml-2 cursor-pointer"
                        src="assets/svgs/question-round-blue.svg" alt="">
                    </div>
                    <prcins-error-label [control]="paymentForm.get('savedCardSecurityCode')"
                      [controlValue]="paymentForm.get('savedCardSecurityCode').value"
                      controlLabel="paymentForm_savedCardSecurityCode"
                      [controlTouched]="paymentForm.get('savedCardSecurityCode').touched">
                    </prcins-error-label>
                  </div>
                </div>
              </div>
              <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="onAddNewCard()">Use a Different Card</a>
              
              <div class="mt-4" >
                <div class="mb-2"><label>Payment Confirmation Email</label></div>
                <div class="flex"><input class="es-textfield flex-grow" type="text"  formControlName="confirmationEmail"
                  ></div>
              
              </div>
            </div>
            <prcins-error-label [control]="paymentForm.get('ccSavedPaymentMethod')"
              [controlValue]="paymentForm.get('ccSavedPaymentMethod').value"
              controlLabel="paymentForm_paymentOptionSelected"
              [controlTouched]="paymentForm.get('ccSavedPaymentMethod').touched">
            </prcins-error-label>

            <div *ngIf="paymentMethod.cc && addNewCard" class="mt-6 border border-t-1 border-l-0 border-r-0 border-b-0">
              <h2 class="mt-4 font-bold text-es-orange text-lg">Account Information</h2>
              <prcins-card-form [cardForm]="paymentForm.get('cardForm')"></prcins-card-form>
              <div *ngIf="paymentSavingAllowed && this.isWalletFlagEnabled" class="custom-check-box">
                <label class="switch" style="display: inline-block; white-space: nowrap; margin-top: 15px">
                  <input type="checkbox" style="vertical-align: middle; width: 30px;
                  height: 30px;" formControlName="saveThisCard">
                  <span class="ml-2" style="vertical-align: middle;">Save this card for future use</span>
                  <div class="tick"></div>
                </label>
              </div>
            </div>
          </div>
        </prcins-card>
        <prcins-notification [notification]="cardNotification"></prcins-notification>
        <div class="flex flex-wrap-reverse justify-between">
          <a class="es-link-secondary w-full mt-4 sm:w-40" [routerLink]="['../..']" (click)="onCancel()"
            prcins-tealium-tracker="blur" prcins-tealium-event="payment_cancel">Cancel</a>
          <button class="es-btn-primary w-full mt-4 sm:w-40" type="submit">Continue</button>
        </div>
      </form>
    </div>
    <prcins-es-popup *ngIf="showGuardPopup" headerClass="bg-es-blue text-white">
      <div header-left>
        <span>Confirmation</span>
      </div>
      <div body>
        <p>Are you sure you want to cancel this transaction?</p>
        <div class="flex flex-wrap-reverse justify-between">
          <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="chooseDeactivate(false)">No</a>
          <button class="es-btn-primary w-full mt-4 sm:w-40" type="button" (click)="chooseDeactivate(true)">Yes</button>
        </div>
      </div>
    </prcins-es-popup>

    <prcins-es-popup *ngIf="showPaymentImpMsg" headerClass="bg-es-blue text-white">
      <div header-left>
        <span style="text-align: center;">Important Message About Your Payment</span>
      </div>
      <div body>
        <p> If your policy has been cancelled, we will accept payment for your outstanding balance, but our acceptance of
          your payment will not automatically reinstate your insurance coverage. Reinstatement is at the company’s sole
          discretion. If you wish to have your insurance coverage reinstated, you must contact your agent or
          <a style="text-decoration: underline;" class="text-es-blue-light" href="https://www.plymouthrock.com/contact-us"
            target="_blank">Customer Solutions</a>
        </p>
        <p class="mt-4"> You can find out the status of your policy at any time by checking <a
            href="https://plymouthrock.com/mypolicy" style="text-decoration: underline;" target="_blank"
            class="text-es-blue-light">plymouthrock.com/mypolicy</a> </p>
        <div class="flex flex-wrap-reverse justify-between">
          <a class="es-link-secondary w-full mt-4 sm:w-40 cursor-pointer" (click)="closeImportantMessage()"
            style="margin: 2rem auto 0;">OK</a>

        </div>
      </div>
    </prcins-es-popup>
    <ng-template #loading>
      <prcins-card pt="pt-0">
        <div body>
          <prcins-skeleton numOfRows="5"></prcins-skeleton>
        </div>
      </prcins-card>
      <prcins-card pt="pt-0">
        <div body>
          <prcins-skeleton numOfRows="10"></prcins-skeleton>
        </div>
      </prcins-card>
    </ng-template>

    <prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.paymentAmount">
      <div header-left>
        <span>Payment Amount</span>
      </div>
      <div header-right>
        <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
          src="assets/svgs/close-hamburger.svg">
      </div>
      <div body>
        <p>Enter the dollar amount you are submitting for payment. Enter any amount, provided it does not exceed your
          outstanding balance.</p>
      </div>
    </prcins-es-popup>

    <prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="helpFlags.savedCardSecurityCode">
      <div header-left>
        <span>CVV / Security Code</span>
      </div>
      <div header-right>
        <img class="cursor-pointer" height='15px' width='15px' (click)="hideHelpText()"
          src="assets/svgs/close-hamburger.svg">
      </div>
      <div body>
        <p>CVV is an anti-fraud security feature to help verify that you are in possession of your debit/credit card.<br>
          For Visa, MasterCard, and Discover, the three-digit CVV number is printed on the signature panel on the back of
          the
          card immediately after the account number.
          <img src="assets/svgs/back_of_card_cvv.svg" width='100px' height='110px' alt="">
          For American Express, the four-digit CVV number is printed on the front
          of the card above the card account number.
        </p>
        <img src="assets/svgs/front_of_card_cvv.svg" width='120px' height='120px' alt="">
      </div>
    </prcins-es-popup>

    <prcins-es-popup *ngIf="areYouSurePopup" headerClass="bg-es-blue text-white">
      <div header-left>
        <span>Are you sure?</span>
      </div>
      <div body>
        <p>You’ve already scheduled a payment.  Making this payment may not stop or change your previously scheduled
          payment.</p>
        <div class="btns">
          <button class="es-btn-primary w-full mt-4" type="button" (click)="doContinue()">Pay Now</button>
          <a class="es-link-secondary w-full mt-4 cursor-pointer" (click)="doNeverMind()">Never mind</a>
        </div>
      </div>
    </prcins-es-popup>

    <prcins-spinner *ngIf="showSpinner"></prcins-spinner>
    </ng-template>
  
</div>