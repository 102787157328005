import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ADMIN_URL } from '@prcins/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

 // private apiUrl = 'http://ci.local.com:8080/feature-flags';

  private apiUrl = `${ADMIN_URL.featureFlags}`

  constructor(private http: HttpClient) { }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.http.get<FeatureFlag[]>(this.apiUrl);
  }

  getFeatureFlagByName(name: String): Observable<FeatureFlag> {
    return this.http.get<FeatureFlag>(`${this.apiUrl}/${name}`);
  }

  bulkUpdateFeatureFlags(featureFlags: FeatureFlag[]): Observable<FeatureFlag[]> {
    return this.http.put<FeatureFlag[]>(`${this.apiUrl}/bulk-update`, featureFlags);
  }

}

export interface FeatureFlag {
  key: string;
  value: boolean;
  description: string;
}
