/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jpm-maintenance.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./jpm-maintenance.component";
var styles_JpmMaintenanceComponent = [i0.styles];
var RenderType_JpmMaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JpmMaintenanceComponent, data: {} });
export { RenderType_JpmMaintenanceComponent as RenderType_JpmMaintenanceComponent };
export function View_JpmMaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We are taking time out for maintenance. We will be back soon."]))], null, null); }
export function View_JpmMaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-jpm-maintenance", [], null, null, null, View_JpmMaintenanceComponent_0, RenderType_JpmMaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.JpmMaintenanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JpmMaintenanceComponentNgFactory = i1.ɵccf("prcins-jpm-maintenance", i2.JpmMaintenanceComponent, View_JpmMaintenanceComponent_Host_0, {}, {}, []);
export { JpmMaintenanceComponentNgFactory as JpmMaintenanceComponentNgFactory };
