/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./desktop-banner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop-banner.component";
var styles_DesktopBannerComponent = [i0.styles];
var RenderType_DesktopBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DesktopBannerComponent, data: {} });
export { RenderType_DesktopBannerComponent as RenderType_DesktopBannerComponent };
export function View_DesktopBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "hidden lg:flex xl:flex flex-col items-center mb-4 pb-8 w-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "max-w-2xl relative w-full desktop-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "welcome-banner w-full flex justify-center pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "welcome-banner"], ["class", "banner-image"], ["src", "assets/svgs/banner-desktop.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "flex justify-center absolute w-full top-40 z-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "welcome-icon"], ["class", "welcome-icon"], ["src", "assets/svgs/welcome-icon.svg"]], null, null, null, null, null))], null, null); }
export function View_DesktopBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-desktop-banner", [], null, null, null, View_DesktopBannerComponent_0, RenderType_DesktopBannerComponent)), i1.ɵdid(1, 49152, null, 0, i2.DesktopBannerComponent, [], null, null)], null, null); }
var DesktopBannerComponentNgFactory = i1.ɵccf("prcins-desktop-banner", i2.DesktopBannerComponent, View_DesktopBannerComponent_Host_0, {}, {}, []);
export { DesktopBannerComponentNgFactory as DesktopBannerComponentNgFactory };
