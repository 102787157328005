import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { GetPolicyWalletsQuery } from '@prcins/utils';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PAYMENT_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class ManagePaymentsProviderService {
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
    }
    get data() {
        return this._data;
    }
    set data(value) {
        this._data = value;
    }
    getPolicyPaymentDetails(userId, policyNumber, appName) {
        return this.apollo
            .watchQuery({
            query: GetPolicyWalletsQuery,
            variables: {
                userId,
                policyNumber,
                appName
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(({ data }) => data), map(({ getWallets }) => getWallets), map((wallets) => {
            if (wallets) {
                wallets.forEach((wallet) => {
                    if (wallet.paymentType === "CC" || wallet.paymentType === "ATM") {
                        const savedCardType = wallet.cardType ? wallet.cardType.toLowerCase() : "";
                        switch (savedCardType) {
                            case "Visa".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/visa-logo.png";
                                wallet.cardDisplayType = "Visa";
                                break;
                            case "Discover".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/discover-logo.png";
                                wallet.cardDisplayType = "Discover";
                                break;
                            case "Mastercard".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/mastercard-logo.png";
                                wallet.cardDisplayType = "Master Card";
                                break;
                            case "Americanexpress".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/amex-logo.gif";
                                wallet.cardDisplayType = "American Express";
                                break;
                            default:
                                if (wallet.paymentType === "CC") {
                                    wallet.imageUrl = "assets/png/payments/generic-credit-card.png";
                                }
                                else {
                                    wallet.imageUrl = "assets/png/payments/generic-debit-card.png";
                                }
                                wallet.cardDisplayType = "     ";
                                break;
                        }
                        const m = wallet.expirationMonth;
                        wallet.expirationMonth = (m < 10 ? '0' : '') + m;
                        wallet.expirationDate = wallet.expirationMonth + "/" + wallet.expirationYear;
                        wallet.expiration = this.getExpiration(wallet);
                    }
                    else if (wallet.paymentType === "ACH") {
                        wallet.accountType = wallet.transType === "CHECKING" ? "Checking" : "Savings";
                    }
                    wallet.modifiedDate = new Date(wallet.modifiedDate);
                    wallet.defaultImage = wallet.defaultInd === "Yes" ? "assets/svgs/default-star-icon.svg" : "assets/svgs/not-default-star-icon.svg";
                });
                return wallets;
            }
        }), map(wallets => { if (wallets) {
            wallets.sort(this.compareByModifiedDate);
        } ; return wallets; }), map(wallets => { if (wallets) {
            wallets.sort(this.compareByDefault);
        } ; return wallets; }));
    }
    getExpiration(wallet) {
        let result = "";
        // month from server starts from 1, so getting next month, 1st day
        let expDate = new Date(wallet.expirationYear, wallet.expirationMonth, 1);
        let now = new Date();
        let diff = expDate.getTime() - now.getTime();
        let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (diffDays <= 0) {
            result = "expired";
        }
        else if (diffDays <= 30) {
            result = "expiring";
        }
        return result;
    }
    compareByModifiedDate(a, b) {
        return b.modifiedDate.getTime() - a.modifiedDate.getTime(); // Sort DESC
    }
    compareByDefault(a, b) {
        if (a.defaultInd === "Yes")
            return -1;
        return 0;
    }
    createWallet(payload, source = "eservice") {
        if (!source) {
            source = "eservice";
        }
        const params = new HttpParams().set("source", source);
        return this.http.post(PAYMENT_URL.ManagePayment, payload, { params });
    }
    deleteWallet(userId, policyNumber, wallet) {
        const params = new HttpParams({ encoder: new CustomEncoder() })
            .set("policyNumber", policyNumber)
            .set("app", "eservice")
            .set("userId", userId);
        return this.http.delete(PAYMENT_URL.ManagePayment + "/" + wallet.debitAccount + "/" + wallet.walletId, { params });
    }
    changeDefaultWallet(userId, policyNumber, wallet) {
        const params = new HttpParams({ encoder: new CustomEncoder() })
            .set("policyNumber", policyNumber)
            .set("app", "eservice")
            .set("userId", userId)
            .set("selection", wallet.defaultInd === "Yes" ? "No" : "Yes");
        return this.http.put(PAYMENT_URL.ManagePayment + "/default/" + wallet.debitAccount + "/" + wallet.walletId, {}, { params });
    }
    editWallet(payload) {
        const params = new HttpParams().set("source", "eservice");
        return this.http.put(PAYMENT_URL.ManagePayment, payload, { params });
    }
}
ManagePaymentsProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManagePaymentsProviderService_Factory() { return new ManagePaymentsProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: ManagePaymentsProviderService, providedIn: "root" });
class CustomEncoder {
    encodeKey(key) {
        return encodeURIComponent(key);
    }
    encodeValue(value) {
        return encodeURIComponent(value);
    }
    decodeKey(key) {
        return decodeURIComponent(key);
    }
    decodeValue(value) {
        return decodeURIComponent(value);
    }
}
