import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HolidayListService {
    constructor(http) {
        this.http = http;
        this.apiUrl = `${ADMIN_URL.holidays}`;
    }
    getHolidays() {
        return this.http.get(this.apiUrl);
    }
    saveHoliday(holidayList) {
        return this.http.post(this.apiUrl, holidayList);
    }
    deleteHoliday(holidayDate) {
        return this.http.delete(`${this.apiUrl}/${holidayDate}`);
    }
    saveHolidays(holidayList) {
        return this.http.post(this.apiUrl, holidayList);
    }
    checkHoliday() {
        return this.http.get(`${this.apiUrl}/check`);
    }
}
HolidayListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HolidayListService_Factory() { return new HolidayListService(i0.ɵɵinject(i1.HttpClient)); }, token: HolidayListService, providedIn: "root" });
export class HolidayList {
}
