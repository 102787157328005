import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prcins-jpm-maintenance',
  templateUrl: './jpm-maintenance.component.html',
  styleUrls: ['./jpm-maintenance.component.css']
})
export class JpmMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
