import { Component, OnInit } from '@angular/core';
import { PolicyProviderService } from '../services/policy-provider.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { AccountLookUpInfo, INotification, LoginService } from '@prcins/utils';
import { data } from 'browserslist';
import { RegisteredPolicyInfo } from '@prcins/utils';



@Component({
  selector: 'prcins-admin-delete-account',
  templateUrl: './admin-delete-account.component.html',
  styleUrls: ['./admin-delete-account.component.css']
})
export class AdminDeleteAccountComponent implements OnInit {

  registeredPolicies: RegisteredPolicyInfo[] = [];
  accountLookupInfo: AccountLookUpInfo ={
  email: '',
  firstName: '',
  lastName:  '',
  phoneNumber: '',
  registeredPolicies:this.registeredPolicies
  };
  modifiedPolicies: RegisteredPolicyInfo[] = [];
  modifiedAccountData: AccountLookUpInfo ={
  email: '',
  firstName: '',
  lastName:  '',
  phoneNumber: null,
  registeredPolicies:this.modifiedPolicies
  };
  tempLastName:string;
  tempFirstName: string;
  fullName:string; 
  notification: INotification;
  isLoading: boolean = false;
  isEditingFirstName: boolean = false;
  isEditingLastName: boolean = false;
  

  constructor(private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService) { }

  /*  ngOnInit() {
     this.isLoading = true;
     this.adminService.adminActivityError.subscribe(data => this.notification = data);
     const email = this.route.snapshot.queryParams.email;
     this.loginService.getAllAssociatedPolicies(email).subscribe(registeredPolicies => {
       this.registeredPolicies = registeredPolicies;
       if (this.registeredPolicies.length) {
         const { policyInfo: accountInfo } = this.registeredPolicies[0];
         this.fullName = accountInfo.firstName + ' ' + accountInfo.lastName;
       } else {
         this.loginService.getActiveAccount(email).subscribe(accountInfo => {
           this.fullName = accountInfo.firstName + ' ' + accountInfo.lastName;
         });
       }
       this.email = email;
       this.isLoading = false;
     }, () => { this.isLoading = false; });
   } */
  ngOnInit() {
    this.isLoading = true;
    this.adminService.adminActivityError.subscribe(data => this.notification = data);
    const email = this.route.snapshot.queryParams.email;
    this.adminService.requestAllRegisteredPolicies(email).subscribe(response => {
      this.accountLookupInfo = response;
      this.registeredPolicies = this.accountLookupInfo.registeredPolicies;
      if (this.accountLookupInfo) {
        this.fullName = this.accountLookupInfo.firstName + " " + this.accountLookupInfo.lastName;
      } else {
        this.loginService.getActiveAccount(email).subscribe(accountInfo => {
          this.fullName = accountInfo.firstName + ' ' + accountInfo.lastName;
        });
      }
      this.accountLookupInfo.email = email;
      this.isLoading = false;
    }, () => { this.isLoading = false; });
  }

  saveFirstName() {
    this.tempFirstName = this.accountLookupInfo.firstName;
    this.isEditingFirstName = false;
    console.log("First Name saved:", this.accountLookupInfo.firstName);
    this.modifiedAccountData.firstName = this.accountLookupInfo.firstName;
    this.modifiedAccountData.lastName = this.accountLookupInfo.lastName;
    this.fullName = this.accountLookupInfo.firstName + ' ' + this.accountLookupInfo.lastName;
  }

  saveLastName() {
    this.tempLastName = this.accountLookupInfo.lastName;
    this.isEditingLastName = false; 
    console.log("First Name saved:", this.accountLookupInfo.lastName); 
    this.modifiedAccountData.lastName = this.accountLookupInfo.lastName;
    this.modifiedAccountData.firstName = this.accountLookupInfo.firstName;
    this.fullName = this.accountLookupInfo.firstName + ' ' + this.accountLookupInfo.lastName;
  }
  
  
  onDisableAccount() {
    this.adminService.disableAccount(this.accountLookupInfo.email, this.registeredPolicies.length ? this.registeredPolicies[0].policyNumber : '').subscribe(data => {
      if (data.messageCode && data.messageCode === "DISABLE_ACCOUNT_SUCCESS") {
        this.router.navigate(['..'], { relativeTo: this.route }).then(data => {
          console.log('Route Callback');
          this.adminService.adminActivityError.next({ type: 'INFO', message: 'Account Disabled' });
        });
      } else {
        this.adminService.adminActivityError.next({ type: 'ERROR', message: 'Disable Account Failed' });
      }
    });
  }

  policyStatusChanged(newValue) {
    const index = this.registeredPolicies.findIndex(policy => policy.policyNumber === newValue.key);
    if (index !== -1) {
      const updatedPolicy: RegisteredPolicyInfo = {
        ...this.registeredPolicies[index],
        status: newValue.value? 'A' : 'I'
      };
      this.updateModifiedPolicies(updatedPolicy);
    }
  }

  private updateModifiedPolicies(updatedPolicy: RegisteredPolicyInfo): void {
    const modifiedIndex = this.modifiedPolicies.findIndex(policy => policy.policyNumber === updatedPolicy.policyNumber);
    if (modifiedIndex !== -1) {
      this.modifiedPolicies[modifiedIndex] = updatedPolicy;
    } else {
      this.modifiedPolicies.push(updatedPolicy);
    }
  }

  onSavePolicyStatus(): void {
    this.isLoading = true;
    if (this.modifiedPolicies.length > 0 || this.modifiedAccountData .firstName !== null || this.modifiedAccountData.lastName !==null) {
      this.modifiedAccountData.email = this.accountLookupInfo.email.toLowerCase();
      this.adminService.updatePolicyStatus(this.modifiedAccountData).subscribe({
        next: (success: boolean) => {
          this.isLoading = false;
          if (success) {
            this.updateRegisteredPolicies();
            this.notification = {
              type: "INFO",
              message: `Updated Successfully`
            }
          } else {
            this.notification = {
              type: "WARN",
              message: `Failed to Update`
            }
          }
          this.modifiedPolicies = [];
        },
        error: (err) => {
          this.accountLookupInfo.firstName = this.tempFirstName;
          this.accountLookupInfo.lastName = this.tempLastName;
          this.isLoading = false;
        }
      });
    } else {
      this.isLoading = false;
    }
  }

  private updateRegisteredPolicies(): void {
    this.modifiedPolicies.forEach(modifiedPolicy => {
      const index = this.registeredPolicies.findIndex(policy => policy.policyNumber === modifiedPolicy.policyNumber);
      if (index !== -1) {
        this.registeredPolicies[index].status = modifiedPolicy.status;
      }
    });
  }
}
