import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BillingDetailsQuery,
  IPolicyGreeting,
  PolicyInfoQuery,
  PolicySearchQuery,
  PolicyUsernameQuery,
  BalanceDetailsQuery,
  MobileRegistrationQuery,
  PolicyCrossSellPromotionsQuery
} from '@prcins/utils';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IBillingDetails } from '../models/billing-details';
import { POLICY_URL ,ROADREWARD_URL} from '@prcins/constants';

@Injectable({
  providedIn: 'root'
})
export class PolicyProviderService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getPolicyInquiry(policyNumber?: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicySearchQuery,
        variables: {
          policyNumber
        }//,
        //fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(map(({ data }: any) => data));
  }

  getCrossSellPromotions(policyNumber?: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyCrossSellPromotionsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data));
  }

  billingQuery(policyNumber?: String): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: BillingDetailsQuery,
      variables: {
        policyNumber
      }
    }).valueChanges;
  }


  balanceDetails(policyNumber?: String): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: BalanceDetailsQuery,
      variables: {
        policyNumber
      }
    }).valueChanges;
  }

  getBillingDetails(policyNumber: string): Observable<IBillingDetails> {
    return this.billingQuery(policyNumber).pipe(
      map(result => {
        const billingDetails = result.data.billingDetails;
        if (billingDetails) {
          //this.cachedBillingData[policyNumber] = true;
          billingDetails.lastPaymentAmnt = this.ifPresent(
            billingDetails.lastPaymentAmnt
          ).replace(',', '');
          billingDetails.nextPaymentAmnt = this.ifPresent(
            billingDetails.nextPaymentAmnt
          ).replace(',', '');
          billingDetails.pastDueAmnt = this.ifPresent(
            billingDetails.pastDueAmnt
          ).replace(',', '');
          billingDetails.balance = this.ifPresent(
            billingDetails.balance
          ).replace(',', '');
        }
        return billingDetails;
      })
    );
  }

  ifPresent(value: string) {
    if (!value) {
      return '';
    }

    return value;
  }

  policyGreetingDetails(
    policyNumber?: String
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery({
      query: PolicyInfoQuery,
      variables: {
        policyNumber: policyNumber
      }
    }).valueChanges;
  }

  getPolicyGreetingDetails(policyNumber: String): Observable<IPolicyGreeting> {
    return this.policyGreetingDetails(policyNumber).pipe(
      map(result => result.data.policySearchInfo)
    );
  }

  getUserName(policyNumber): Observable<string> {
    return this.apollo
      .watchQuery({
        query: PolicyUsernameQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(
        map(({ data }: any) => {
          return data.username || null;
        })
      );
  }
  
  getPayloadForBillingAlerts(policyNumber) {
    return this.apollo
      .watchQuery({
        query: MobileRegistrationQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  linkPolicy(payload, userId, userType) {
    return this.http.post(
      `${POLICY_URL.addPolicy}/${userId}/${userType}`,
      payload
    );
  }
  
  getRRDriversDetails(policyNumber): Observable<any> {
    return this.http.get(ROADREWARD_URL.DriverDetails + '/' + policyNumber).pipe(
      map(response => {
        return { errorCode: null, data: { drivers: response } };
      }),
      catchError(error => {
        return of({ errorCode: "RR-FAILURE", data: {} });
      })
    );
  }
}
