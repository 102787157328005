import { Apollo } from 'apollo-angular';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RightRailsQuery, RightRailsHOEvidenceQuery, RightRailsEdocsQuery } from '../graphql/rightrails-schema';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
export class RightRailService {
    constructor(apollo) {
        this.apollo = apollo;
        this.showAgentInfo$ = new BehaviorSubject(false);
        this.agentInfoLoaded$ = new BehaviorSubject(false);
    }
    quickLinkQuery(policyNumber) {
        return this.apollo
            .watchQuery({
            query: RightRailsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data, loading }) => {
            console.log("Right Rail Loading : ", loading);
            let response = data.rightRail.quickLinks;
            if (data.rightRail.showEdocs) {
                response.showEdocs = data.rightRail.showEdocs;
            }
            return response;
        }));
    }
    getQuickLinks(policyNumber) {
        return this.quickLinkQuery(policyNumber).pipe(map(result => {
            return result;
        }));
    }
    getFlagsFromCache(policyNumber) {
        return this.apollo.watchQuery({
            query: RightRailsQuery,
            fetchPolicy: 'cache-first',
            variables: {
                policyNumber
            }
        }).valueChanges.pipe(map(({ data }) => {
            if (data && data.rightRail && data.rightRail && data.rightRail.quickLinks) {
                return data.rightRail.quickLinks.showWallet;
            }
        }));
    }
    getEdocs(policyNumber) {
        return this.apollo
            .watchQuery({
            query: RightRailsEdocsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => {
            return data.rightRail.showEdocs;
        }));
    }
    getHomeEvidence(policyNumber) {
        return this.apollo
            .watchQuery({
            query: RightRailsHOEvidenceQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => {
            return data.rightRail.showHomeEvidence;
        }));
    }
}
RightRailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RightRailService_Factory() { return new RightRailService(i0.ɵɵinject(i1.Apollo)); }, token: RightRailService, providedIn: "root" });
