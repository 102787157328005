/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-feature-flags.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../libs/utils/src/lib/round-toggle-switch/round-toggle-switch.component.ngfactory";
import * as i3 from "../../../../../libs/utils/src/lib/round-toggle-switch/round-toggle-switch.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./admin-feature-flags.component";
import * as i7 from "../services/feature-flag.service";
var styles_AdminFeatureFlagsComponent = [i0.styles];
var RenderType_AdminFeatureFlagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminFeatureFlagsComponent, data: {} });
export { RenderType_AdminFeatureFlagsComponent as RenderType_AdminFeatureFlagsComponent };
function View_AdminFeatureFlagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Updating feature flags, please wait..."]))], null, null); }
function View_AdminFeatureFlagsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "success-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.successMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_AdminFeatureFlagsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_AdminFeatureFlagsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "border border-t-0 border-r-0 border-l-0 border-b-1 pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "flex justify-between items-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " (", ")"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "prcins-round-toggle-switch", [], null, [[null, "sliderChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sliderChanged" === en)) {
        var pd_0 = (_co.featureFlagChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RoundToggleSwitchComponent_0, i2.RenderType_RoundToggleSwitchComponent)), i1.ɵdid(7, 114688, null, 0, i3.RoundToggleSwitchComponent, [], { sliderKey: [0, "sliderKey"], sliderValue: [1, "sliderValue"], disabled: [2, "disabled"] }, { sliderChanged: "sliderChanged" })], function (_ck, _v) { var currVal_2 = _v.context.$implicit.key; var currVal_3 = _v.context.$implicit.value; var currVal_4 = false; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.description; var currVal_1 = _v.context.$implicit.key; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_AdminFeatureFlagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminFeatureFlagsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminFeatureFlagsComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminFeatureFlagsComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminFeatureFlagsComponent_4)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "flex justify-between mt-4 flex-wrap-reverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "mt-4 es-btn-secondary w-full lg:w-40 text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "mt-4 es-btn-primary w-full lg:w-40 text-center"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveFeatureFlags() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Update Feature Flags"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.successMessage; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.errorMessage; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.featureFlags; _ck(_v, 7, 0, currVal_3); var currVal_4 = _ck(_v, 11, 0, "../"); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_AdminFeatureFlagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-admin-feature-flags", [], null, null, null, View_AdminFeatureFlagsComponent_0, RenderType_AdminFeatureFlagsComponent)), i1.ɵdid(1, 114688, null, 0, i6.AdminFeatureFlagsComponent, [i7.FeatureFlagService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminFeatureFlagsComponentNgFactory = i1.ɵccf("prcins-admin-feature-flags", i6.AdminFeatureFlagsComponent, View_AdminFeatureFlagsComponent_Host_0, {}, {}, []);
export { AdminFeatureFlagsComponentNgFactory as AdminFeatureFlagsComponentNgFactory };
