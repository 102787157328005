import gql from 'graphql-tag';

export const ActiveAccountQuery = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      accountInfo {
        firstName
        lastName
        active
      }
    }
  }
`;

export const AllAssociatedPolicyQuery = gql`
  query policyAssociations($username: String!) {
    policyAssociations(username: $username) {
      registeredPolicies {
        policyNumber
        policyInfo {
          lob
          productCode
          firstName
          lastName
          isPrime
          state
          companyCode
          statusCode
          zip
        }
      }
    }
  }
`;

export const ViewPolicyAsGuestQuery = gql`
  query viewPolicyAsGuest($policyNumber: String!) {
    viewPolicyAsGuest(policyNumber: $policyNumber) {
        policyNumber
        policyInfo {
          lob
          productCode
          firstName
          lastName
          isPrime
          state
          companyCode
          statusCode
          zip
        }
        inquiry {
          nameInsured{
            email
          }
        }
      }
  }
`;

export const RegisteredPolicyQuery = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      registeredPolicies {
        policyNumber
        
        policyInfo {
          lob
          productCode
          firstName
          lastName
          isPrime
          state
          companyCode
          statusCode
          zip
        }
      }
    }
  }
`;

export const MobileRegisteredPolicyQuery = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      registeredPolicies {
        policyNumber
        
        policyInfo {
          lob
          productCode
          firstName
          lastName
          isPrime
          state
          companyCode
          statusCode
        }

        inquiry{
          vehicles{
            entityCode
          }
        }
      }
    }
  }
`;

export const PolicyPerksQuery = gql`
  query PolicyPerks($policyNumber: String) {
    policyPerks(policyNumber: $policyNumber) {
      perkType
      perkDetails {
        perkDescription
        perkName
        perkURL
      }
    }
  }
`;

export const PolicyInfoQuery = gql`
  query PolicyInfo($policyNumber: String!) {
    policySearchInfo(policyNumber: $policyNumber) {
      effectiveDate
      expirationDate
      statusCode
      lob
      productCode
      firstName
    }
  }
`;

export const PolicyCrossSellPromotionsQuery = gql`
  query PolicyCrossSellPromotions($policyNumber: String!) {
    policyCrossSellPromotions(policyNumber: $policyNumber) {
      crossSellType
      discountPercentage
    }
  }
`;


export const PolicySearchQuery = gql`
  query PolicySearch($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      policyInstance
      product {
        policyNumber
        status
        effectiveDate
        state
        lobCode
        edocPromise
        cancelPending
        cancelPendingDate
        productCode
        issuedDate
        channel
        companyCode
        communicationNum1 
        num1Mobile
        communicationNum2
        num2Mobile
        branchCode
      }
      locations {
        entityCode
      }
    }
    policySearchInfo(policyNumber: $policyNumber) {
      underwriterCode
      firstName
      lastName
    }
    rightRail(policyNumber: $policyNumber) {
      quickLinks{
        generalPhone
        isEFT
        isREC
        isNOIC
        isPayRollDeduct
        productDesciption
      }
      showEdocs
    }
  }
`;

export const PolicyDriversQuery = gql`
  query PolicyDrivers($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      drivers {
        gender
        lastName
        middleName
        firstName
        licenseStatus
        birthDate
        seqId
       identifier
       displayOrder 
       participantId
       assignedToVehicleFlag
       cellphoneNumber
       mobilePhoneInteractionCode
      }
      product {
       channel
       programRsCode
     }
      incidents {
        incidentType
        incidentDate
        driverName
      }
      primaryNIparticipantId
      secondaryNIparticipantId

    }
  }
`;

export const PolicyEndorseDriverQuery = gql`
  query PolicyEndorseDriver($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      isGenderXEligible
      product {
        effectiveDate
        expirationDate
        state
        companyCode
        productCode
        channel
        policyTerm
        policyNumber
        agencyCode
         }
      
      primaryAddress {
        addressLine1
        city
        state
        zipCode
      }
    }
  }
`;

export const PolicyVehiclesQuery = gql`
  query PolicyVehicles($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      vehicles {
        vin
        unitYear
        make
        model
        bodyType
        premium
        id
      }
    }
  }
`;

export const PolicyDiscountsQuery = gql`
  query PolicyDiscounts($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      discounts {
        discountName
        discountType
        driverFirstName
        driverLastName
        vehicleYear
        vehicleMake
        vehicleModel
      }
    }
  }
`;

export const PolicyBillPlanChangeQuery = gql`
  query BillPlanChange($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      product {
        state
        companyCode
        policyNumber
        effectiveDate
        lobCode
      }
      nameInsured {
        firstName
        middleName
        lastName
        email
        primaryInsuredFlag
      }
      locations {
        entityCode
    }
    }
    billingDetails(policyNumber: $policyNumber) {
      businessType
    }
    policySearchInfo(policyNumber: $policyNumber) {
      termLength
      underwriterCode
      productCode
    }
    rightRail(policyNumber: $policyNumber) {
      quickLinks{
        generalPhone
      }
    }
  }
`;

export const PolicyUsernameQuery = gql`
  query PolicyUsername($policyNumber: String) {
    username(policyNumber: $policyNumber)
  }
`;

export const RegisteredPolicyList = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      registeredPolicies {
        policyNumber
        policyInfo {
          lob
        }
      }
    }
  }
`;

export const PolicyPremiumDetailsQuery = gql`
  query PolicyPremiumDetails($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      premium {
        totalPremium
        startDate
        endDate
        premiumEntries {
          premiumName
          premiumAmt
          premiumType
        }
      }
    }
  }
`;

export const PropertyInfoQuery = gql`
  query PolicyInfoQuery($policyNumber: String) {
    addressList(policyNumber: $policyNumber) {
      addresses {
        id
        addressLine1
        addressLine2
        zipCode
        city
        state
      }
      primary
      mailing
    }
    policyDetails(policyNumber: $policyNumber) {
      nameInsured {
        firstName
        middleName
        lastName
      }
      locations {
        entityCode
        insurableInterests {
          nameBusiness
          address1
          city
          stateCode
          zipCode
          identifier
          interestTypeCode
          loanNumber
        }
      }
    }
  }
`;

export const CoverageDetailsQuery = gql`
  query CoverageDetailsQuery($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      product {
        totalPremium
        subTotal
        tax
        taxLabel
      }
    }
    coverages(policyNumber:$policyNumber){
      policyCoverages {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
      }
      personalCoverages {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText     
        subCoverages{
          coverageLabel
          coverageLimits
        } 
      }
      vehicleCoverages {
        id
        unitYear
        bodyType
        make
        model
        coverages{
          coverageCd
          coverageLabel
          coverageLimits
          premium
          selection
          deductible
          helpText
        }
      }
      packages {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
        subCoverages{
          coverageLabel
        }
      }
      perks {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
        subCoverages{
          coverageCd
          coverageLabel
          premium
          helpText
        }
      }
    }
    }
`;

export const CoverageDetailsHomeQuery = gql`
  query CoverageDetailsHomeQuery($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      product {
        totalPremium
        subTotal
        tax
        taxLabel
      }
    }
    coverages(policyNumber:$policyNumber){
      deductibleCoverages {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
      }
      liabilityCoverages {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
      }
    mandatoryCoverages {
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
    }
    optionalCoverages{
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
    }
    propertyCoverages{
        coverageCd
        coverageLabel
        coverageLimits
        premium
        selection
        deductible
        helpText
    }
    }
    }
`;

export const ManageBillingAlertsPolicyList = gql`
  query Account($username: String!) {
    account(username: $username, userType: "idg_user_group") {
      registeredPolicies {
        policyNumber
        inquiry{
          product{
            payPlan
            status
            productCode
            lobCode
          }
        }
      }
    }
  }
`;

export const PolicyAddressSearchQuery = gql`
  query addressQuery($policyNumber: String) {
    policyDetails(policyNumber: $policyNumber) {
      primaryAddress {
        addressLine1
        city
        zipCode
        state
      }
    }
   
  }
`;
