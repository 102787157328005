import gql from 'graphql-tag';

export const RightRailsQuery = gql`
  query RightRails($policyNumber: String!) {
    rightRail(policyNumber: $policyNumber) {
      quickLinks{
        showEft
        showPayPlanChange
        showAddressChange
        showAutoProof
        showWallet
        generalPhone
        generalEmail
        claimsPhone
        mrbActive
        showEServiceTxtMsg
        edocsEligible
        agentInfo {
          agencyName
          agencyAddress
          agencyCity
          agencyState
          agencyZip
          agencyPhone
        }
      }
      showEdocs
    }
  }
`;

export const RightRailsEdocsQuery = gql`
  query RightRailsEdocs($policyNumber: String!) {
    rightRail(policyNumber: $policyNumber) {
      showEdocs  
    }
  }
`;

export const RightRailsHOEvidenceQuery = gql`
  query RightRailsEdocs($policyNumber: String!) {
    rightRail(policyNumber: $policyNumber) {
      showHomeEvidence  
    }
  }
`;


