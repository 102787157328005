<prcins-header></prcins-header>
<prcins-spinner *ngIf="showSpinner"></prcins-spinner>
<main class="flex justify-center w-full ">
  <div class="es-container w-full flex justify-between flex-wrap">
    <section class=" w-full px-4">
      <main class="px-4 max-w-full lg:pl-16 xl:pl-16">
        <h1 class="es-h1 mt-4">Admin Activity</h1>
        <hr />

        <prcins-tabs class="max-w-full">
          <prcins-tab title="Support">
            <ng-container>
              <prcins-notification [notification]="notification"></prcins-notification>
              <form [formGroup]="adminActivityForm">
                <div class="flex mt-4 items-center">

                  <input id="policy" type="radio" formControlName="activity" value="policyNumber">
                  <span class="ml-2">Policy lookup to redirect to e-Service.</span>

                </div>
                <div class="flex mt-4 items-center">
                  <input id="email" type="radio" formControlName="activity" value="email">
                  <span class="ml-2">Email lookup to disable account.</span>
                </div>
                <div *ngIf="superUser" class="flex mt-4 items-center">

                  <input id="bulkInvite" type="radio" formControlName="activity" value="bulkInviteUpload">
                  <span class="ml-2">Bulk Invitation Document Upload.</span>

                </div>
                <div *ngIf="superUser" class="flex mt-4 items-center">

                  <input id="endorsementBlock" type="radio" formControlName="activity" value="endorsementBlockUpload">
                  <span class="ml-2">Endorsement Block Document Upload.</span>

                </div>

                <div class="flex flex-col mt-4" formArrayName="activityInput"
                  *ngFor="let inputControl of arrayInput; let i = index;">
                  <label for="">{{inputControl.label}}</label>
                  <input *ngIf="inputControl.controlType === 'text'" class="es-textfield mt-2" type="text" trimInput
                    [formControl]="activityInput.controls[i]">
                  <input *ngIf="inputControl.controlType === 'file'" class="es-textfield mt-2"
                    [formControl]="activityInput.controls[i]" (change)="onFileChange($event)" type="file">

                  <div
                    *ngIf="!activityInput.controls[i].valid  && (activityInput.controls[i].dirty || activityInput.controls[i].touched)">
                    <div class="es-error-field" *ngIf="activityInput.controls[i].errors['required']">
                      {{inputControl.label}} is reqiured
                    </div>
                    <div class="es-error-field"
                      *ngIf="activityInput.controls[i].errors['email'] || activityInput.controls[i].errors['pattern']">
                      {{inputControl.label}} is not a valid email address
                    </div>
                  </div>
                </div>
              </form>

              <div class="flex justify-center mt-4">
                <button class="es-btn-primary" (click)="onSubmit()" type="button"
                  [disabled]="adminActivityForm.valid ? '':'disabled'">Go!</button>
              </div>
            </ng-container>
          </prcins-tab>
          <prcins-tab title="Batch">
            No Jobs Yet
          </prcins-tab>
          <prcins-tab title="Flags" *ngIf="superUser">
            <prcins-admin-feature-flags></prcins-admin-feature-flags>
            
            
          </prcins-tab>
          <prcins-tab title="Holidays" *ngIf="superUser">
            <prcins-holiday-list></prcins-holiday-list>
          </prcins-tab>
          
        </prcins-tabs>





        <hr />
      </main>

      <prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="showUnsupportedFile">
        <div header-left>
          <span>Unsupported File</span>
        </div>
        <div body>
          <div class="flex justify-between mt-2 sm:px-2">
            <span>Invalid file type, we support {{supportedFilesList}}. Please <a class="es-color-blue"
                (click)="retryUpload()">Retry</a></span>
          </div>

          <div class="flex justify-between sm:px-2 mb-4">
            <a class="mt-4 es-btn-primary text-center" (click)="closeUnsupportedFilePopUp()">OK</a>
          </div>

        </div>
      </prcins-es-popup>


      <prcins-es-popup headerClass="bg-es-blue text-white" *ngIf="showUnsupportedFileSize">
        <div header-left>
          <span>Unsupported File Size</span>
        </div>
        <div body>
          <div class="flex justify-between mt-2 sm:px-2">
            <span> Your file is too big, maximum size is 25MB. Please <a class="es-color-blue"
                (click)="closeUnsupportedFilePopUp()">Retry</a> </span>

          </div>

          <div class="flex justify-between sm:px-2 mb-4">
            <a class="mt-4 es-btn-primary text-center" (click)="closeUnsupportedFilePopUp()">OK</a>
          </div>

        </div>
      </prcins-es-popup>



    </section>
  </div>
</main>