import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentProviderService } from '../services/payment-provider.service';
import {
  ADMIN_QPAY_TOKEN,
  CURRENT_POLICY,
  EMPLOYEE_QPAY_PAYMENT_SOURCE,
  PAYMENT_AMOUNT,
  PAYMENT_ID,
  PAYMENT_SOURCE,
  QPAY_PAYMENT_DETAILS,
  QPAY_REGISTRATION_EMAIL,
  SAVED_WALLET,
  SAVE_WALLET_ERROR,
  USER_ID
} from '@prcins/constants';
import { IPolicyDetails, IAddressDetails, DiscardComponent } from '@prcins/utils';
import { tap, switchMapTo } from 'rxjs/operators';
import { BRAND, Brand } from '@prcins/constants';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Bank, Card } from '../model/wallet';
import { Observable } from 'rxjs';

@Component({
  selector: 'prcins-payment-review',
  templateUrl: './payment-review.component.html',
  styleUrls: ['./payment-review.component.css']
})
export class PaymentReviewComponent extends DiscardComponent implements OnInit {
  policyNumber;
  paymentDetails: any;
  policyDetails: IPolicyDetails;
  addressDetails: IAddressDetails;
  userDetails;
  isReviewLoaded = false;
  isConfirmInProgress = false;
  isReviewError = false;
  reviewErrorMessage = '';
  params;
  brand = localStorage.getItem(BRAND);
  companyName = Brand[this.brand].companyName;
  isSavedPaymentMethod: boolean;
  validationCounter: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymentProvider: PaymentProviderService,
    private managePaymentsProviderService: ManagePaymentsProviderService
  ) { super(); }

  ngOnInit() {
    this.deactivateGuardSelection$.subscribe(selection => {
      if (selection) {
        this.paymentProvider.resetACHFailureCount();
      }
    });
    this.validationCounter = 0;
    this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
    this.params = this.route.snapshot.queryParams;
    const paymentD = this.paymentProvider.getPolicyPaymentDetails();
    this.paymentDetails = paymentD.paymentDetails;
    if (!this.paymentDetails) {
      this.paymentProvider
        .getPaymentDetailsFromSession(this.policyNumber)
        .pipe(
          tap(data => {
            this.paymentDetails = data;
            this.isSavedPaymentMethod = !!(this.paymentDetails.savedPaymentMethod);
          }),
          switchMapTo(
            this.paymentProvider.getPolicyReviewDetails(this.policyNumber)
          )
        )
        .subscribe(({ policyDetails, policySearchInfo }) => {
          this.policyDetails = policyDetails.product;
          this.addressDetails = policyDetails.primaryAddress;
          this.userDetails = policySearchInfo;
          this.isReviewLoaded = true;
        });
    } else {
      this.paymentProvider
        .getPolicyReviewDetails(this.policyNumber)
        .subscribe(({ policyDetails, policySearchInfo }) => {
          this.policyDetails = policyDetails.product;
          this.addressDetails = policyDetails.primaryAddress;
          this.userDetails = policySearchInfo;
          this.isReviewLoaded = true;
        });
    }
    this.updateSafeToNavigate(false);
  }

  onEditInfo() {
    this.updateSafeToNavigate(true);
    this.router.navigate(['../entry'], { relativeTo: this.route, queryParams: this.params });
  }

  onAuthorizePayment(paymentMethod) {
    this.isConfirmInProgress = true;
    this.updateSafeToNavigate(true);
    if (paymentMethod === 'cc') {
      this.onCardPayment();
    } else if (paymentMethod === 'eft') {
      this.onEftPayment();
    }
  }

  onCardPayment() {
    const cardPayload = this.isSavedPaymentMethod ? this.buildSavedCardPaymentRequest() : this.buildCardPaymentRequest();
    this.paymentProvider
      .makePayment('card', cardPayload)
      .subscribe(data => this.handlePaymentResponse(data), error => this.handleServiceError());
  }

  onEftPayment() {
    this.isSavedPaymentMethod = !!(this.paymentDetails.savedPaymentMethod);
    const accountPayload = this.isSavedPaymentMethod ? this.buildSavedBankPaymentRequest() : this.buildBankPaymentRequest();
    this.paymentProvider
      .makePayment('cheque', accountPayload)
      .subscribe(data => this.handlePaymentResponse(data), error => this.handleServiceError());
  }

  getPolicyNameDetails() {

    // const { businessName } = this.policyDetails;
    let { firstName, lastName, businessName } = this.userDetails;

    if (businessName) {
      if (businessName.length > 15) {
        firstName = businessName.substr(0, 15);
        lastName = businessName.substr(15, 50);
      } else {
        const si = businessName.indexOf(' ');
        if (si !== -1) {
          firstName = businessName.substr(0, si);
          lastName = businessName.substr(si, 50);
        }
      }
    }
    return { firstName, lastName, middleName: this.userDetails.middleName };
  }

  basePaymentRequest() {
    const {
      productCode,
      lobCode,
      effectiveDate,
      companyCode,
      channel,
      programIdCode,
      branchCode
    } = this.policyDetails;

    const {
      addressLine1: address,
      state,
      city,
      zipCode: zip
    } = this.addressDetails;

    const { paymentAmount, paymentOptionSelected } = this.paymentDetails;

    // Save amount in session for confirm page message on refresh
    sessionStorage.setItem(PAYMENT_AMOUNT, paymentAmount);

    const { firstName, lastName, middleName } = this.getPolicyNameDetails();
    let paymentMethod = 'electronic_withdrawal';
    let cardType;

    if (paymentOptionSelected === 'cc') {
      paymentMethod = 'credit_debit';
      cardType = 'credit';
    }
    let isAdmin = sessionStorage.getItem(ADMIN_QPAY_TOKEN);
    return {
      paymentAmount,
      policyFirstName: firstName,
      policyLastName: lastName,
      policyMiddleName: middleName,
      policyNumber: this.policyNumber,
      policyStreet1: address,
      policyState: state,
      policyCity: city,
      policyZip: zip.substring(0, 5),
      policy_Effective_Date: effectiveDate,
      uwCompany: companyCode,
      lob: productCode === 'PCAT' || productCode === 'ALN_UMB' ? 'PCAT' : lobCode,
      productCd: productCode === 'ALN_MC' ? 'ALN_PA' : productCode,
      payment_method: paymentMethod,
      card_type: cardType,
      app: (self != top) ? 'mobileapp' : (isAdmin && isAdmin.toLowerCase() === 'true') ? EMPLOYEE_QPAY_PAYMENT_SOURCE : sessionStorage.getItem(PAYMENT_SOURCE) || 'eservice',
      channelCode: channel,
      programIdCode,
      branchCd : branchCode
    };
  }

  buildCardPaymentRequest() {
    const {
      cardForm: { cardNumber, securityCode, expirationDate }
    } = this.paymentDetails;

    return this.returnCardRequest(securityCode, expirationDate, cardNumber);
  }

  returnCardRequest(securityCode: string, expirationDate: string, cardNumber: string) {
    const basePayload = this.basePaymentRequest();

    const {
      cardForm: { cardHolderName, cardHolderEmailId }
    } = this.paymentDetails;

    const {
      addressLine1: address,
      state,
      city,
      zipCode: zip
    } = this.addressDetails;

    return {
      ...basePayload,
      email: cardHolderEmailId,
      cardCvv: securityCode,
      cardExpiration: expirationDate,
      cardNumber,
      cardOwnerName: cardHolderName,
      cardStreet1: address,
      cardState: state,
      cardCity: city,
      cardZip: zip.substring(0, 5)
    };
  }

  buildSavedCardPaymentRequest() {
    const {
      debitAccount: cardNumber, expirationDate
    } = this.paymentDetails.savedPaymentMethod;
    sessionStorage.removeItem(SAVED_WALLET);
    return this.returnCardRequest(this.paymentDetails.savedCardSecurityCode, expirationDate, 'X' + cardNumber);
  }

  buildBankPaymentRequest() {
    const {
      eftForm: { accountType, accountNumber, routingNumber }
    } = this.paymentDetails;
    return this.returnBankRequest(accountType, accountNumber, routingNumber);
  }

  returnBankRequest(accountType: any, accountNumber: any, routingNumber: any) {
    const basePayload = this.basePaymentRequest();
    if(this.paymentDetails.confirmationEmail) {
       this.paymentDetails.eftForm.accountEmailId = this.paymentDetails.confirmationEmail;
    }
    const {
      eftForm: { accountHolderName, accountEmailId }
    } = this.paymentDetails;
    const {
      addressLine1: address,
      state,
      city,
      zipCode: zip
    } = this.addressDetails;
    const bankAccountType = accountType === 'C' ? 'checking' : 'saving';
    const transactionType = accountType === 'C' ? 'D' : 'S';

    return {
      ...basePayload,
      email: accountEmailId,
      bankAccountType,
      checkAccountNumber: accountNumber,
      checkRoutingNumber: routingNumber,
      checkOwnerName: accountHolderName,
      checkStreet1: address,
      checkState: state,
      checkCity: city,
      checkZip: zip.substring(0, 5),
      transactionType
    };
  }

  buildSavedBankPaymentRequest() {
    let {
      transType: accountType, debitAccount: accountNumber, debitRouting: routingNumber
    } = this.paymentDetails.savedPaymentMethod;
    sessionStorage.removeItem(SAVED_WALLET);
    accountType = accountType === "CHECKING" ? 'C' : 'S';
    return this.returnBankRequest(accountType, 'X' + accountNumber, routingNumber);
  }

  handleServiceError() {
    this.isConfirmInProgress = false;
    this.isReviewError = true;
    this.reviewErrorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
  }

  handlePaymentResponse(response) {

    let savedPaymentMethod$: Observable<any>;
    if (!response.errorCode) {
      this.paymentProvider.resetACHFailureCount();
      sessionStorage.setItem(PAYMENT_ID, response.paymentId);
      if (this.paymentDetails.saveThisBank) {
        savedPaymentMethod$ = this.saveBankPaymentMethod();
      } else if (this.paymentDetails.saveThisCard) {
        savedPaymentMethod$ = this.saveCardPaymentMethod();
      } else {
        this.isConfirmInProgress = false;
        this.navigateToConfirm();
      }
      savedPaymentMethod$.subscribe(
        (response) => {
          if (response == null || response.status === 'success' && response.error === "") {
            sessionStorage.removeItem(SAVE_WALLET_ERROR);
          } else {
            sessionStorage.setItem(SAVE_WALLET_ERROR, "Error: " + response.error);
          }
          this.isConfirmInProgress = false;
          this.navigateToConfirm();
        }, (response: HttpErrorResponse) => {
          sessionStorage.setItem(SAVE_WALLET_ERROR, response.error.message);
          this.navigateToConfirm();
        }
      );
    } else {
      if (this.paymentDetails.paymentOptionSelected === 'cc') {
        this.handleCardError(response);
      } else {
        this.handleEFTError(response);
      }
    }
  }

  handleCardError(response) {
    this.isConfirmInProgress = false;
    this.isReviewError = true;
    this.reviewErrorMessage = response.errorMessage;
  }

  handleEFTError(response) {
    let isACHFailure = false;
    this.isConfirmInProgress = false;
    
    let error = { code: response.errorCode };
    switch (response.errorCode) {
      case "4533003":
        error['message'] = "The Routing Number and Account Number provided cannot be used. Please provide a different payment method to continue with your payment.";
        this.paymentProvider.incrementACHFailureCount();
        isACHFailure = true;
        break;
      case "4533005":
        error['message'] = "The Routing Number provided cannot be validated. Please correct the routing number to continue with the payment.";
        this.paymentProvider.incrementACHFailureCount();
        isACHFailure = true;
        break;
      case "4533006":
        error['message'] = "The Account Number provided cannot be validated. Please correct the account number to continue with the payment.";
        this.paymentProvider.incrementACHFailureCount();
        isACHFailure = true;
        break;
      default:
        this.isReviewError = true;
        this.reviewErrorMessage = response.errorMessage;
    }

    if (isACHFailure) {
      if (this.paymentProvider.getACHFailureCount() > 2) {
        this.isReviewError = true;
        this.reviewErrorMessage = "The Routing Number and Account Number provided cannot be used. Please provide a different payment method to continue with your payment.";
      } else {
        this.updateSafeToNavigate(true);
        this.router.navigate(['../entry'], { relativeTo: this.route, queryParams: this.params, state: { error } });
      }
    }
  }

  saveBankPaymentMethod(): Observable<any> {
    let eftForm = this.paymentDetails.eftForm;
    sessionStorage.setItem(SAVED_WALLET, this.getSavedBankLabel(eftForm));
    var newBank = new Bank();
    const accountNumber: string = eftForm.accountNumber;
    newBank.debitAccount = accountNumber;
    newBank.debitRouting = eftForm.routingNumber;
    newBank.creditAccount = this.policyNumber;
    newBank.accountEmail = sessionStorage.getItem(USER_ID);
    newBank.paymentType = "ACH";
    const accountType = eftForm.accountType;
    if (accountType === 'C') {
      newBank.transType = "CHECKING";
    } else if (accountType === 'S') {
      newBank.transType = "SAVINGS";
    }
    return this.savePaymentMethod(newBank);
  }

  getSavedBankLabel(eftForm: any): string {
    return (eftForm.accountType === 'C' ? "Checking" : "Savings") + " *****" + eftForm.accountNumber.substring(eftForm.accountNumber.length - 4);
  }

  private savePaymentMethod(wallet: any): Observable<any> {
    wallet.debitZip = "12345"; // TODO - remove after ACI Speedpay fix
    return this.managePaymentsProviderService.createWallet(wallet, sessionStorage.getItem(PAYMENT_SOURCE));
  }

  saveCardPaymentMethod(): Observable<any> {
    let cardForm = this.paymentDetails.cardForm;
    sessionStorage.setItem(SAVED_WALLET, this.getSavedCardLabel(cardForm));
    // this.showSpinner = true;
    var newCard = new Card();
    newCard.debitAccount = cardForm.cardNumber;
    newCard.cvv = cardForm.securityCode;
    newCard.expirationMonth = cardForm.expirationMonth;
    newCard.expirationYear = cardForm.expirationYear;
    newCard.creditAccount = this.policyNumber;
    newCard.accountEmail = sessionStorage.getItem(USER_ID);
    newCard.paymentType = "CC"; // TODO - ATM Card?
    newCard.transType = "CARD";
    return this.savePaymentMethod(newCard);
  }

  getSavedCardLabel(cardForm: any): string {
    return cardForm.cardType + " *****" + cardForm.cardNumber.substring(cardForm.cardNumber.length - 4);
  }

  navigateToConfirm() {

  this.paymentProvider
      .getPaymentDetailsFromSession(this.policyNumber)
      .subscribe((policyDetails) => {
        if (policyDetails) {
          // put payment email for intermediate registeration
          let emailAddress;
          if (policyDetails && policyDetails.paymentOptionSelected === "cc") {
            const { confirmationEmail, cardForm: {cardHolderEmailId}} = policyDetails;
            emailAddress= confirmationEmail? confirmationEmail : cardHolderEmailId;
          } else if (policyDetails && policyDetails.paymentOptionSelected === "eft"){
            const { confirmationEmail, eftForm : {accountEmailId}} = policyDetails;
            emailAddress= confirmationEmail? confirmationEmail : accountEmailId;
          }
          if (emailAddress) {
            sessionStorage.setItem(QPAY_REGISTRATION_EMAIL, emailAddress);
          }
          // clear the session before navigating to confirmation
          sessionStorage.removeItem(QPAY_PAYMENT_DETAILS);

          this.router.navigate(['../confirm'], {
            relativeTo: this.route
          });
        }
      });
    
  }

  cancelPayment() {
    sessionStorage.removeItem(QPAY_PAYMENT_DETAILS);
  }

  disableAutorization(): Boolean {
    return this.paymentDetails.paymentOptionSelected === 'eft' && this.paymentProvider.getACHFailureCount() > 2;
  }
}
