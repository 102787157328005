import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import { AccountLookUpInfo, INotification } from '@prcins/utils';
import { RegisteredPolicyInfo } from '@prcins/utils';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  adminActivityError = new Subject<INotification>();

  constructor(private http: HttpClient) { }

  disableAccount(email: string, policyNumber: string): Observable<any> {
    let url;
    if (policyNumber) {
      url = `${ADMIN_URL.disableAccount}/${email}/idg_user_group/${policyNumber}`
    } else {
      url = `${ADMIN_URL.disableAccount}/${email}/idg_user_group/null`;
    }
    return this.http.delete(url, {});
  }
  validateSuperAdmin(): Observable<any> {

    let url = ADMIN_URL.validateSuperAdmin+"?name=superUser";

    return this.http.get(url);

  }

  requestAllRegisteredPolicies(userId: String): Observable<any> {
    return this.http.get(`${ADMIN_URL.getAllRegisteredPolicies}/${userId}`);
  }

  /* Update the Policy status when changed thorugh admin login*/
  updatePolicyStatus(accountLookUpInfo: AccountLookUpInfo): Observable<boolean> {
    return this.http.put<boolean>(`${ADMIN_URL.updatePolicyStatus}`, accountLookUpInfo);
  }
}
