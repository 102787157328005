import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FeatureFlagService {
    constructor(http) {
        this.http = http;
        // private apiUrl = 'http://ci.local.com:8080/feature-flags';
        this.apiUrl = `${ADMIN_URL.featureFlags}`;
    }
    getFeatureFlags() {
        return this.http.get(this.apiUrl);
    }
    getFeatureFlagByName(name) {
        return this.http.get(`${this.apiUrl}/${name}`);
    }
    bulkUpdateFeatureFlags(featureFlags) {
        return this.http.put(`${this.apiUrl}/bulk-update`, featureFlags);
    }
}
FeatureFlagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagService_Factory() { return new FeatureFlagService(i0.ɵɵinject(i1.HttpClient)); }, token: FeatureFlagService, providedIn: "root" });
