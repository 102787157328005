import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ADMIN_URL } from '@prcins/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HolidayListService {

  private apiUrl = `${ADMIN_URL.holidays}`

  constructor(private http: HttpClient) { }

  getHolidays(): Observable<HolidayList[]> {
    return this.http.get<HolidayList[]>(this.apiUrl);
  }

  saveHoliday(holidayList: HolidayList): Observable<HolidayList> {
    return this.http.post<HolidayList>(this.apiUrl, holidayList);
  }

  deleteHoliday(holidayDate: String): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.apiUrl}/${holidayDate}`);
  }

  saveHolidays(holidayList: HolidayList[]): Observable<Boolean> {
    return this.http.post<Boolean>(this.apiUrl, holidayList);
  }

  checkHoliday(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/check`);
  }
}

export class HolidayList {
  holidayDate: Date;
  holidayType: String;
  holidayDescription: String;
}