import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable,Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RightRailsQuery, RightRailsHOEvidenceQuery, RightRailsEdocsQuery } from '../graphql/rightrails-schema';
import { IRightRail } from '../models/policy';


@Injectable({
  providedIn: 'root'
})
export class RightRailService {
  constructor(private apollo: Apollo) { }
  showAgentInfo$ =  new BehaviorSubject<boolean>(false);
  agentInfoLoaded$ =  new BehaviorSubject<boolean>(false);

  quickLinkQuery(policyNumber?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: RightRailsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data, loading }: any) => {
        console.log("Right Rail Loading : ", loading);
        let response = data.rightRail.quickLinks;
        if (data.rightRail.showEdocs) {
          response.showEdocs = data.rightRail.showEdocs;
        }
        return response;
      }));
  }

  getQuickLinks(policyNumber: String): Observable<IRightRail> {
    return this.quickLinkQuery(policyNumber).pipe(
      map(result => {
        return result;
      })
    );
  }
  getFlagsFromCache(policyNumber: String): Observable<boolean> {
    return this.apollo.watchQuery({
      query: RightRailsQuery,
      fetchPolicy: 'cache-first',
      variables: {
        policyNumber
      }
    }).valueChanges.pipe(map(({data}:any)=> {
      if(data && data.rightRail && data.rightRail && data.rightRail.quickLinks) {
        return data.rightRail.quickLinks.showWallet;
      }
    }));
  }

  getEdocs(policyNumber?: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: RightRailsEdocsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => {
        return data.rightRail.showEdocs;
      }));
  }

  getHomeEvidence(policyNumber?: String): Observable<boolean> {
    return this.apollo
      .watchQuery({
        query: RightRailsHOEvidenceQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => {
        return data.rightRail.showHomeEvidence;
      }));
  }
}
