/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tab.component";
var styles_TabComponent = [i0.styles];
var RenderType_TabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_TabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "prcins-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i1.ɵdid(1, 49152, null, 0, i3.TabComponent, [], null, null)], null, null); }
var TabComponentNgFactory = i1.ɵccf("prcins-tab", i3.TabComponent, View_TabComponent_Host_0, { title: "title", active: "active" }, {}, ["*"]);
export { TabComponentNgFactory as TabComponentNgFactory };
