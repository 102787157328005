import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HolidayListService } from '../services/holiday-list.service';
import { formatDate } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/internal/operators';

@Component({
  selector: 'prcins-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.css']
})
export class HolidayListComponent implements OnInit {
  holidayList = [];
  isLoading = false;
  successMsg: string |null = null;
  errorMsg: string |null = null;
  showDeletePopup = false;
  canAddRow = true;
  private deleteHolidayConfirmationSubject = new Subject<boolean>();


  constructor(private holidayListService : HolidayListService, private router: Router ) { }

  ngOnInit() {

    this.holidayListService.getHolidays().subscribe((data) => {
      this.holidayList = data? data.map(holiday => ({
        ...holiday,
        holidayDate: this.formatDate(holiday.holidayDate),
        isNew: false,
        isError: false
      })): [];
    })

  }
 
  addRow() {
    if(!this.canAddRow) {
      return;
    }
    this.successMsg = this.isLoading = this.errorMsg = null;
    this.holidayList.push({holidayDescription: '', holidayDate: '', holidayType: '', isNew: true, isError: false});
    this.canAddRow = false;
  }

  deleteRow(index, holiday) {
    this.showDeletePopup = true;
    this.getDeleteHolidayConfirmationResponse().pipe(take(1)).subscribe((data) => {
      this.showDeletePopup = false;
      if(data === true) {
        this.removeRow(index, holiday);
      } else {
        return;
      }
    })
  }

  getDeleteHolidayConfirmationResponse(): Observable<any> {
    return this.deleteHolidayConfirmationSubject.asObservable();
  }

  deleteHoliday(result:boolean) {
    this.deleteHolidayConfirmationSubject.next(result);
  }

  removeRow(index, holiday) {
    this.isLoading = true;
    if(holiday.isNew) {
      this.isLoading = false;
      this.canAddRow = true;
      this.holidayList.splice(index,1);  
      this.successMsg = 'Holiday Deleted!';
    } else {
      this.holidayListService.deleteHoliday(holiday.holidayDate).subscribe({
        next: (deleteRow) => {
          this.isLoading = false;
          if(deleteRow === true) {
            this.holidayList.splice(index,1);
            this.successMsg = 'Holiday Deleted!';
          } else {
            holiday.isError = true;
            this.errorMsg = 'Error Deleting holiday';
          }
        },
        error: (err) => {
          this.isLoading = false;
          holiday.isError = true;
          this.errorMsg = 'Error Deleting holiday';
        }
      })
    }
  }

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd', 'en_Us');
  }

  save(holiday) {
    let list = [];
    list.push(holiday);
    this.isLoading = true;
    this.holidayListService.saveHolidays(list).subscribe({
      next: (save) => {
        this.isLoading = false;
        if(save === true) {
          holiday.isNew = false;
          this.canAddRow = true;
          this.successMsg = 'Holiday Saved!';
        } else {
          holiday.isError = true;
          this.errorMsg = 'Error Saving holiday';
        }
      },
      error: (err) => {
        this.isLoading = false;
        holiday.isError = true;
        this.errorMsg = 'Error Saving holiday';
      }
    }
    );
  }


}
